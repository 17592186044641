import React, { useEffect } from "react";

import { Home } from "./views";
import "./App.scss";

/**
 * App component
 */
export const App: React.FC = () => {
  useEffect(() => {
    const updateCSSVars = () =>
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );

    window.addEventListener("resize", updateCSSVars);
    updateCSSVars();

    return () => window.removeEventListener("resize", updateCSSVars);
  }, []);

  return <Home />;
};

export default App;
