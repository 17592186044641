import type { SizeModiferLengths } from '../../types';

export const LANDING_NAME_SIZE_LENGTHS: SizeModiferLengths = {
  xs: 32,
  sm: 28,
  md: 25,
  lg: 23,
  xl: 21,
};

export const LANDING_TITLE_SIZE_LENGTHS: SizeModiferLengths = {
  xs: 45,
  sm: 43,
  md: 40,
  lg: 38,
  xl: 36,
};

export const LANDING_STATUS_SIZE_LENGTHS: SizeModiferLengths = {
  xs: 60,
  sm: 56,
  md: 53,
  lg: 50,
  xl: 48,
};

export const LANDING_STATUSALT_SIZE_LENGTHS: SizeModiferLengths = {
  xs: 63,
  sm: 59,
  md: 56,
  lg: 53,
  xl: 50,
};

export const LANDING_PAST_SIZE_LENGTHS: SizeModiferLengths = {
  xs: 63,
  sm: 61,
  md: 59,
  lg: 57,
  xl: 56,
};
