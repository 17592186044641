import React, { useMemo, useState } from "react";
import { useInterval, useMount } from "react-use";
import cx from "classnames";

import { Response } from "../../types";

import "./index.scss";

const DEFAULT_ERROR = "An unexpected error has occured";
const MAX_DOTS = 4;
const UPDATE_DOTS_RATE = 2000;

/**
 * Loader props
 */
interface IProps<T> {
  load?: () => Promise<T>;
  animation: React.FC<{ title: React.ReactNode }>;
  children: (data: T) => React.ReactNode;
}

/**
 * Loader component
 *
 * usage: <Loader loader?={V} animation?={W} onSuccess?={X} onError?={Y} errorMessage?={Z}>...</Loader>
 */
export const Loader = <T,>({
  load,
  animation: Animation,
  children,
}: IProps<T>) => {
  const [elapsed, setElapsed] = useState(0);
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState("");

  useInterval(
    () => {
      setElapsed((elapsed + 1) % MAX_DOTS);
    },
    data || error ? null : UPDATE_DOTS_RATE
  );

  useMount(() => {
    load?.().then(setData, (error: Error) => setError(error.message));
  });

  const loadingText = useMemo(
    () => (
      <>
        <span className="loader-text">LOADING</span>
        {Array.from({ length: MAX_DOTS }, (_, i) => (
          <span key={i} className={cx("loader-text", i >= elapsed && "hidden")}>
            .
          </span>
        ))}
      </>
    ),
    [elapsed]
  );

  return !error && data ? (
    <>{children(data)}</>
  ) : (
    <div
      className={cx("loader", error && "error", data ? "loaded" : "loading")}
    >
      <Animation
        title={
          error ? (
            <>
              <h2 className="loader-text">ERROR:</h2>
              <p className="loader-text">{error || DEFAULT_ERROR}</p>
            </>
          ) : (
            <h2>{loadingText}</h2>
          )
        }
      />
    </div>
  );
};

export default Loader;
