import { createContext, MutableRefObject } from "react";

export const ID_NONE = "0";
export const COLORS_NONE = { primary: "#363636", secondary: "#262626" };

export interface ThemeColor {
  primary: string;
  secondary: string;
}

export interface PanelColorState {
  current: ThemeColor;
  next?: ThemeColor;
}

export interface ThemeContext {
  color: PanelColorState;
  panelColors: Record<string, PanelColorState>;
  registerPanel: (id: string, ref: MutableRefObject<HTMLDivElement>) => void;
  unregisterPanel: (id: string) => void;
  registerToggle: (
    id: string,
    ref: MutableRefObject<HTMLElement>,
    panelId: string,
    colors: ThemeColor
  ) => void;
  unregisterToggle: (id: string) => void;
}

export const defaultContext: ThemeContext = {
  color: { current: COLORS_NONE },
  panelColors: {},
  registerPanel: () => undefined,
  unregisterPanel: () => undefined,
  registerToggle: () => undefined,
  unregisterToggle: () => undefined,
};

export const Context = createContext(defaultContext);

export default Context;
