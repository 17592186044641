import { CSSProperties, FC, useCallback, useContext, useMemo } from "react";

import DimensionConstraintsContext from "../../contexts/dimension-constraints";
import { usePanel } from "../../components/theme";

import "./index.scss";

/**
 * Panel higher-order component
 *
 * usage: withPanel(<SomeWrappedComponent/>)
 */
const withPanel = <T,>(WrappedComponent: FC<T>) => {
  const Panel = (props: T) => {
    const constraints = useContext(DimensionConstraintsContext);
    const [ref, panelId, color] = usePanel();

    const maxWidthFn = useCallback((screenWidth: number) => {
      if (screenWidth > 1500) {
        return 0.7 * screenWidth;
      }
      if (screenWidth > 1200) {
        return 0.85 * screenWidth;
      }
      return 0.9 * screenWidth;
    }, []);

    const maxHeightFn = useCallback((screenHeight: number) => {
      return 0.6 * screenHeight;
    }, []);

    const context = useMemo(
      () => ({
        ...constraints,
        panel: {
          maxWidthFn,
          maxHeightFn,
        },
      }),
      [constraints, maxWidthFn, maxHeightFn]
    );

    return (
      <table
        className="panel"
        style={
          {
            "--theme__current--primary": color?.current.primary,
            "--theme__current--secondary": color?.current.secondary,
            "--theme__next--primary": color?.next?.primary,
            "--theme__next--secondary": color?.next?.secondary,
          } as CSSProperties
        }
      >
        <tbody>
          <tr>
            <td>
              <div data-panelid={panelId} ref={ref} className="panel__wrapped">
                <DimensionConstraintsContext.Provider value={context}>
                  <WrappedComponent {...props} panelId={panelId} />
                </DimensionConstraintsContext.Provider>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  Panel.displayName = WrappedComponent.displayName;
  return Panel;
};

export default withPanel;
