import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import cx from "classnames";

import DimensionConstraintsContext from "../../contexts/dimension-constraints";
import { Ribbon, Toggles } from "../../components";
import { withPanel } from "../../hoc";
import { LandingData } from "../../data-types";
import { useSizeModifier } from "../../utils/ui";

import {
  LANDING_NAME_SIZE_LENGTHS,
  LANDING_PAST_SIZE_LENGTHS,
  LANDING_STATUS_SIZE_LENGTHS,
  LANDING_STATUSALT_SIZE_LENGTHS,
  LANDING_TITLE_SIZE_LENGTHS,
} from "./constants";

import "./index.scss";

export const LandingSection = ({
  headshot,
  name,
  title,
  social,
  statusAlt,
  status,
  panelId,
  past,
}: LandingData) => {
  const { width } = useWindowSize();
  const headshotRef = useRef<HTMLImageElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const [isCondensed, setIsCondensed] = useState(false);

  const nameSizeModifier = useSizeModifier(name, LANDING_NAME_SIZE_LENGTHS);
  const titleSizeModifier = useSizeModifier(title, LANDING_TITLE_SIZE_LENGTHS);
  const statusSizeModifier = useSizeModifier(
    status,
    LANDING_STATUS_SIZE_LENGTHS
  );
  const statusAltSizeModifier = useSizeModifier(
    statusAlt,
    LANDING_STATUSALT_SIZE_LENGTHS
  );
  const pastSizeModifier = useSizeModifier(past, LANDING_PAST_SIZE_LENGTHS);

  const { maxWidthFn: panelMaxWidthFn } =
    useContext(DimensionConstraintsContext).panel ?? {};
  const { offsetWidth: headshotWidth } = headshotRef.current ?? {};
  const { offsetWidth: infoWidth } = infoRef.current ?? {};

  useEffect(() => {
    const panelMaxWidth = panelMaxWidthFn?.(width);
    if (!panelMaxWidth || !headshotWidth || !infoWidth) {
      setIsCondensed(false);
    } else {
      setIsCondensed(headshotWidth + infoWidth >= Math.floor(panelMaxWidth));
    }
  }, [headshotWidth, infoWidth, width, panelMaxWidthFn]);

  return (
    <div
      className={cx("section-landing", {
        "section-landing--condensed": isCondensed,
      })}
    >
      <img
        ref={headshotRef}
        className="section-landing__headshot"
        alt="headshot"
        src={headshot}
      />
      <div ref={infoRef} className="section-landing__info">
        <h1
          className={cx(
            "section-landing__name",
            `section-landing__name--${nameSizeModifier}`
          )}
        >
          {name}
        </h1>
        <h2
          className={cx(
            "section-landing__title",
            `section-landing__title--${titleSizeModifier}`
          )}
        >
          {title}
        </h2>
        <div
          className={cx(
            "section-landing__status",
            `section-landing__status--${statusSizeModifier}`
          )}
        >
          <Ribbon type={isCondensed ? undefined : "right"}>{status}</Ribbon>
        </div>
        {statusAlt && (
          <p
            className={cx(
              "section-landing__status-alt",
              `section-landing__status-alt--${statusAltSizeModifier}`
            )}
          >
            {statusAlt}
          </p>
        )}
        <h5
          className={cx(
            "section-landing__past",
            `section-landing__past--${pastSizeModifier}`
          )}
        >
          {/* prevent whitespace wrapping of company names by grouping desired words into 'blocks' with white-space: no-wrap */}
          {past.map((block, key) => (
            <span
              key={key}
              className="format__textblock format__textblock--bar"
            >
              {block}
            </span>
          ))}
        </h5>
        <Toggles
          isMobile={isCondensed}
          type="sm"
          panelId={panelId}
          data={social}
        />
      </div>
    </div>
  );
};

export default withPanel(LandingSection);
