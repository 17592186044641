import { memo, useContext, useEffect, useRef, useState } from "react";
import { useMap, useWindowSize } from "react-use";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Parallax from "parallax-js";

import ThemeContext from "../../../../contexts/theme";

import { THEME_VARIATION_GEOMETRY1_PARTICLES_CONFIG } from "./constants";

import "./index.scss";

const TRANSFORM_BACKGROUND_NONE = "";
const TRANSFORM_FOREGROUND_NONE = "";

interface Props {
  onNavigateUp?: () => void;
  onNavigateDown?: () => void;
}

const ParticlesInstance = memo(() => (
  <Particles
    data-invert-y="true"
    data-invert-x="true"
    options={THEME_VARIATION_GEOMETRY1_PARTICLES_CONFIG}
  />
));

export const Geometry1 = ({ onNavigateUp, onNavigateDown }: Props) => {
  const [initParticles, setInitParticles] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { color } = useContext(ThemeContext);
  const [transforms, { setAll: setTransforms }] = useMap({
    background: TRANSFORM_BACKGROUND_NONE,
    foreground: TRANSFORM_FOREGROUND_NONE,
  });
  const parallaxRef = useRef<HTMLDivElement>(null);

  const setTransformsRef = useRef(setTransforms);
  setTransformsRef.current = setTransforms;
  useEffect(() => {
    const offsetY = windowHeight / 2;
    const angle = -Math.atan(windowHeight / windowWidth) * (180 / Math.PI);
    setTransformsRef.current({
      background: `translateY(${offsetY}px) skewY(${angle}deg)`,
      foreground: `translateY(${-offsetY}px) skewY(${-angle}deg)`,
    });
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    initParticlesEngine(loadSlim).then(() => {
      setInitParticles(true);
    });
    const parallax = parallaxRef.current
      ? new Parallax(parallaxRef.current)
      : undefined;
    return () => {
      parallax?.disable();
    };
  }, []);

  return (
    <div
      className="theme__bg-primary"
      style={{ backgroundColor: color.current.primary }}
    >
      <div
        className={cx("theme__bg-secondary")}
        style={{
          backgroundColor: color.current.secondary,
          transform: transforms.background,
        }}
      >
        <div style={{ transform: transforms.foreground }}>
          <div ref={parallaxRef}>
            <div data-depth="0.3">
              {initParticles ? <ParticlesInstance /> : null}
            </div>
          </div>
          <span
            onClick={onNavigateUp}
            className={cx("theme__nav", "theme__nav--up", {
              "theme__nav--hidden": !onNavigateUp,
            })}
          >
            <FontAwesomeIcon icon={["fas", "chevron-up"]} />
          </span>
          <span
            onClick={onNavigateDown}
            className={cx("theme__nav", "theme__nav--down", {
              "theme__nav--hidden": !onNavigateDown,
            })}
          >
            <FontAwesomeIcon icon={["fas", "chevron-down"]} />
          </span>
          <span className="theme__credits">
            <h6>
              Designed by{" "}
              <a
                className="themify--inactive-color-invert-hover"
                href="https://github.com/alipianu"
                rel="noopener noreferrer"
                target="_blank"
              >
                @alipianu
              </a>
            </h6>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Geometry1;
