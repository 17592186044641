import { useMemo } from 'react';
import { isArray } from 'lodash';

import type { SizeModiferLengths } from '../types';

const SIZES_DESC = ['xl', 'lg', 'md', 'sm', 'xs'];

/**
 * Retrieves size modifier for a string
 */
export const getSizeModifier = (len: number, lenSizes: SizeModiferLengths) => {
  let prevSize: number | null = null;

  for (const size of SIZES_DESC) {
    const sizeLen = lenSizes[size as keyof typeof lenSizes];
    if (len <= sizeLen) {
      return size;
    } else if (prevSize !== null && sizeLen <= prevSize) {
      throw Error(
        'The provided size modifiers map does not satisfy the following constraint: xs < sm < md < lg < xl'
      );
    }
    prevSize = sizeLen;
  }

  throw new Error(
    `The length ${len} is too long, please shorten the string so that the UI can render it properly.`
  );
};

/**
 * Retrieves size modifier for a string
 */
export const useSizeModifier = (
  item: string | string[] | undefined,
  lenSizes: SizeModiferLengths
) => {
  const itemAsStr = useMemo(
    () =>
      (isArray(item) ? item.join(' | ') : item ?? '')
        .replace(/[\s]{2,}/g, ' ')
        .replace(/^[\s]|[\s]$/g, ''),
    [item]
  );
  return useMemo(
    () => getSizeModifier(itemAsStr.length, lenSizes),
    [itemAsStr.length, lenSizes]
  );
};
