import "./polyhedrons.scss";
import { ReactNode, useEffect, useState } from "react";
import cx from "classnames";

const DEFAULT_ID = -1;

// shape counter
export let counter = 0;

interface Props {
  type: string;
  animatedId: number;
  children?: (parentId: number) => ReactNode;
}

export const Polyhedron = ({ type, animatedId, children }: Props) => {
  const [id, setId] = useState(DEFAULT_ID);

  useEffect(() => {
    setId(counter++);
    return () => {
      counter -= 1;
    };
  }, []);

  return (
    <div className={cx("shape", type, animatedId === id && "animated")}>
      {children?.(id)}
    </div>
  );
};

export default Polyhedron;
