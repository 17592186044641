import { createContext } from "react";

export const WIDTH_NONE = 0;
export const HEIGHT_NONE = 0;

export interface DimensionConstraintsContext {
  panel?: {
    maxWidthFn: (screenWidth: number) => number;
    maxHeightFn: (screenHeight: number) => number;
  };
}

export const defaultContext: DimensionConstraintsContext = {};

export const Context = createContext(defaultContext);

export default Context;
