import React from "react";
import ReactDOM from "react-dom/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBook,
  faChalkboard,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCode,
  faCodeBranch,
  faExchangeAlt,
  faGlobe,
  faInfoCircle,
  faLevelUpAlt,
  faLock,
  faProjectDiagram,
  faScroll,
  faServer,
  faSync,
  faTimes,
  faUser,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCodepen,
  faDocker,
  faFigma,
  faGithub,
  faGooglePlay,
  faJs,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "./index.scss";
import App from "./App";

// build font awesome library
library.add(
  // solid
  faCode,
  faCodeBranch,
  faBook,
  faScroll,
  faProjectDiagram,
  faExchangeAlt,
  faChevronDown,
  faChevronUp,
  faChalkboard,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faLock,
  faLevelUpAlt,
  faGlobe,
  faTimes,
  faServer,
  faVial,
  faSync,
  faUser,
  // brands
  faGithub,
  faLinkedinIn,
  faCodepen,
  faDocker,
  faJs,
  faFigma,
  faGooglePlay
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
