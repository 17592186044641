import LandingSection from "./landing";

import { LandingData } from "../data-types";

export type SectionName = "LandingSection";

// Sections module
export { LandingSection };

// Record<string, Section> exported as default for dynamic section rendering from API
const sections = {
  LandingSection,
};

export const render = (
  name: SectionName,
  data: LandingData,
  key?: string | number
) => {
  switch (name) {
    case "LandingSection":
      return <LandingSection {...(data as LandingData)} key={key} />;
    default:
      return null;
  }
};

export default sections;
