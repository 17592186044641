import { ForwardedRef, forwardRef, ReactNode } from "react";
import cx from "classnames";

import "./index.scss";

type RibbonType = "double" | "left" | "right";

interface Props {
  type?: RibbonType;
  title?: string;
  className?: string;
  children?: ReactNode;
}

/**
 * Ribbon component
 *
 * usage: <Ribbon innerRef?={W} type?={X} title?={Y} className?={Z}>...</Ribbon>
 */
export const Ribbon = forwardRef(
  (
    { type = "double", title, className, children }: Props,
    ref?: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={cx("ribbon", `ribbon--${type}`, {
        "ribbon--titled": title,
      })}
    >
      <div className={className}>
        <div className="ribbon__left"></div>
        <div className="ribbon__right"></div>
        {title && <span className="ribbon__title">{title}</span>}
        <span className="ribbon__text">{children}</span>
      </div>
    </div>
  )
);
Ribbon.displayName = "Ribbon";

export default Ribbon;
