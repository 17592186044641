import config from '../config.json';

/**
 * GET request
 */
export const get = async (service = '', path = '') => {
  const routePrefix = service === 's3' ? config.s3 : `${config.api}/${service}`;
  const response = await fetch(`${routePrefix}${path}`);
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result);
  }
  return result;
};

/**
 * POST request
 */
export const post = async (
  service = '',
  path = '',
  data: Record<string, string> = {}
) => {
  if (service === 's3') {
    throw new Error('Unable to send POST request to S3');
  }
  const response = await fetch(`${config.api}/${service}${path}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: Object.keys(data)
      .map(
        (field) =>
          `${encodeURIComponent(field)}=${encodeURIComponent(data[field])}`
      )
      .join('&'),
    redirect: 'follow',
    credentials: 'include',
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result);
  }
  return result;
};

/**
 * Send beacon
 */
export const beacon = (service = '', path = '', data = '') =>
  navigator.sendBeacon(`${config.api}/${service}${path}`, data);

const Request = {
  get,
  post,
  beacon,
};
export default Request;
