import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIsomorphicLayoutEffect, useSetState, useUnmount } from "react-use";
import { last, uniq, uniqueId } from "lodash";

import ThemeContext, {
  COLORS_NONE,
  ID_NONE,
  PanelColorState,
  ThemeColor,
} from "../../contexts/theme";

import variations from "./variations";

type ThemeVariation = keyof typeof variations;

const DEFAULT_VARIATION: ThemeVariation = "Geometry1";

const getRGB = (hex: string) => ({
  r: parseInt(hex.slice(1, 3), 16),
  g: parseInt(hex.slice(3, 5), 16),
  b: parseInt(hex.slice(5, 7), 16),
});

const TOGGLE_ID_PREFIX = "toggle_";
const PANEL_ID_PREFIX = "panel_";

const INTERSECT_ACCURACY = 0.025;
const INTERSECT_OPTIONS: { threshold: number[] } = { threshold: [] };

// build intersect thresholds of INTERSECT_OPTIONS using INTERSECT_ACCURACY
let accuracy = 0;
while (accuracy < 1) {
  INTERSECT_OPTIONS.threshold.push(accuracy);
  accuracy += INTERSECT_ACCURACY;
}
if (last(INTERSECT_OPTIONS.threshold) !== 1) {
  INTERSECT_OPTIONS.threshold.push(1);
}

interface State {
  panelIds: string[];
  panelRefs: Record<string, MutableRefObject<HTMLDivElement>>;
  toggleHandlers: Record<
    string,
    { click: () => void; mouseenter: () => void; mouseleave: () => void }
  >;
  togglePanelIds: Record<string, string>;
  toggleRefs: Record<string, MutableRefObject<HTMLElement>>;
  intersectRatios: Record<string, number>;
  maxIntersectId: string;
  panelColors: Record<string, PanelColorState>;
  toggleColors: Record<string, ThemeColor>;
  nextColor?: ThemeColor;
  nav: { up?: string; down?: string };
}

interface Props {
  variation?: ThemeVariation;
  children?: ReactNode;
}

type StateIntersectPatch = Partial<Omit<State, "intersectRatios">> &
  Pick<State, "intersectRatios">;

export const usePanel = (): [
  RefObject<HTMLDivElement>,
  string,
  PanelColorState | undefined
] => {
  const { panelColors, registerPanel, unregisterPanel } =
    useContext(ThemeContext);
  const ref = useRef<HTMLDivElement>(null);
  const [id] = useState(uniqueId(PANEL_ID_PREFIX));

  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    registerPanel(id, ref as MutableRefObject<HTMLDivElement>);
    return () => {
      unregisterPanel(id);
    };
  }, [ref.current]);

  return [ref, id, panelColors[id]];
};

// TODO(alipianu): remove panelId from here
export const useToggle = (panelId: string, colors: ThemeColor) => {
  const { registerToggle, unregisterToggle } = useContext(ThemeContext);
  const ref = useRef<HTMLElement>(null);
  const [id] = useState(uniqueId(TOGGLE_ID_PREFIX));

  useIsomorphicLayoutEffect(() => {
    if (!ref.current || panelId === ID_NONE) {
      return;
    }
    registerToggle(id, ref as MutableRefObject<HTMLElement>, panelId, colors);
    return () => {
      unregisterToggle(id);
    };
  }, [ref.current]);

  return ref;
};

export const Theme = ({ variation = DEFAULT_VARIATION, children }: Props) => {
  const [state, setState] = useSetState<State>({
    panelIds: [],
    panelRefs: {},
    toggleHandlers: {},
    togglePanelIds: {},
    toggleRefs: {},
    intersectRatios: {},
    maxIntersectId: ID_NONE,
    panelColors: {},
    toggleColors: {},
    nextColor: undefined,
    nav: {},
  });
  const [intersectObserver] = useState(
    new IntersectionObserver(
      (entries: Parameters<IntersectionObserverCallback>[0]) => {
        const newIntersectRatios: Record<string, number> = {};
        let newMaxIntersect = { id: ID_NONE, ratio: 0 };

        for (const { intersectionRatio, target } of entries) {
          // round to 3 decimal places
          const ratio = Math.round(intersectionRatio * 1000) / 1000;
          const id = (target as HTMLElement).dataset.panelid ?? "";
          if (id) {
            if (ratio >= newMaxIntersect.ratio) {
              newMaxIntersect = { id, ratio };
            }
            newIntersectRatios[id] = ratio;
          }
        }

        setState(({ intersectRatios, panelIds, maxIntersectId }) => {
          const patch: StateIntersectPatch = {
            intersectRatios: { ...intersectRatios, ...newIntersectRatios },
          };
          if (
            maxIntersectId === ID_NONE ||
            (newMaxIntersect.id !== maxIntersectId &&
              newMaxIntersect.ratio > patch.intersectRatios[maxIntersectId])
          ) {
            const navIndex = panelIds.findIndex(
              (id) => id === newMaxIntersect.id
            );
            patch.maxIntersectId = newMaxIntersect.id;
            patch.nav = {
              up: navIndex ? panelIds[navIndex - 1] : undefined,
              down:
                navIndex < panelIds.length - 1
                  ? panelIds[navIndex + 1]
                  : undefined,
            };
          }
          return patch;
        });
      },
      INTERSECT_OPTIONS
    )
  );

  /** Panel-related registration & unregistration */

  const registerPanel = useCallback(
    (id: string, ref: MutableRefObject<HTMLDivElement>) => {
      if (state.panelRefs[id]) return;
      intersectObserver.observe(ref.current);
      setState(({ panelIds, panelRefs, panelColors }) => ({
        panelIds: uniq([...panelIds, id]),
        panelRefs: { ...panelRefs, [id]: ref },
        panelColors: {
          ...panelColors,
          [id]: {
            current: COLORS_NONE,
          },
        },
      }));
    },
    [state.panelRefs, intersectObserver, setState]
  );

  const unregisterPanel = useCallback(
    (id: string) => {
      const { [id]: ref, ...panelRefs } = state.panelRefs;
      if (ref) {
        intersectObserver.unobserve(ref.current);
        setState(({ panelIds, intersectRatios, panelColors }) => {
          delete intersectRatios[id];
          delete panelColors[id];
          return {
            panelIds: panelIds.filter((panelId) => panelId !== id),
            panelRefs,
            intersectRatios,
            panelColors,
          };
        });
      }
    },
    [intersectObserver, state.panelRefs, setState]
  );

  /** Toggle-related registration & unregistration */

  const registerToggle = useCallback(
    (
      id: string,
      ref: MutableRefObject<HTMLElement>,
      panelId: string,
      colors: ThemeColor
    ) => {
      if (state.toggleRefs[id]) return;

      const handleToggleClick = () => {
        setState(({ panelColors }) => {
          // TODO(alipianu): fix bug where clicking on a toggle quickly while scrolling causes page to jump back to where you came from
          // panelRefs[togglePanelIds[id]].current.scrollIntoView();
          return {
            panelColors: {
              ...panelColors,
              [panelId]: {
                current:
                  panelColors[panelId].current === colors
                    ? COLORS_NONE
                    : colors,
              },
            },
          };
        });
      };

      const handleToggleMouseEnter = () => {
        setState(({ panelColors }) => ({
          nextColor: colors,
          panelColors: {
            ...panelColors,
            [panelId]: {
              current: panelColors[panelId].current,
              next: colors,
            },
          },
        }));
      };

      const handleToggleMouseLeave = () => {
        setState(({ panelColors }) => ({
          nextColor: undefined,
          panelColors: {
            ...panelColors,
            [panelId]: {
              current: panelColors[panelId].current,
            },
          },
        }));
      };

      setState(
        ({ toggleHandlers, togglePanelIds, toggleRefs, toggleColors }) => ({
          toggleHandlers: {
            ...toggleHandlers,
            [id]: {
              click: handleToggleClick,
              mouseenter: handleToggleMouseEnter,
              mouseleave: handleToggleMouseLeave,
            },
          },
          togglePanelIds: { ...togglePanelIds, [id]: panelId },
          toggleRefs: { ...toggleRefs, [id]: ref },
          toggleColors: { ...toggleColors, [id]: colors },
        })
      );

      ref.current.addEventListener("click", handleToggleClick);
      ref.current.addEventListener("mouseenter", handleToggleMouseEnter);
      ref.current.addEventListener("mouseleave", handleToggleMouseLeave);
    },
    [state.toggleRefs, setState]
  );

  const unregisterToggle = useCallback(
    (id: string) => {
      const { [id]: ref, ...toggleRefs } = state.toggleRefs;
      if (ref) {
        const { [id]: handlers, ...toggleHandlers } = state.toggleHandlers;
        for (const handler in handlers) {
          ref.current.removeEventListener(
            handler,
            handlers[handler as keyof typeof handlers]
          );
        }
        setState(({ togglePanelIds, toggleColors }) => {
          const { [id]: _, ...newTogglePanelIds } = togglePanelIds;
          const { [id]: __, ...newToggleColors } = toggleColors;
          return {
            toggleHandlers,
            togglePanelIds: newTogglePanelIds,
            toggleRefs,
            toggleColors: newToggleColors,
          };
        });
      }
    },
    [state.toggleRefs, state.toggleHandlers, setState]
  );

  // TODO(alipianu): provide through context and expose as css variable
  const currentColor = useMemo(() => {
    let totalRatio = 0;
    const primary = { r: 0, g: 0, b: 0 };
    const secondary = { r: 0, g: 0, b: 0 };

    for (const id in state.intersectRatios) {
      const ratio = state.intersectRatios[id];

      if (!ratio) {
        continue;
      }

      const panelColor = state.panelColors[id].current;

      const rgbPrimary = getRGB(panelColor.primary);
      primary.r += rgbPrimary.r * ratio;
      primary.g += rgbPrimary.g * ratio;
      primary.b += rgbPrimary.b * ratio;

      const rgbSecondary = getRGB(panelColor.secondary);
      secondary.r += rgbSecondary.r * ratio;
      secondary.g += rgbSecondary.g * ratio;
      secondary.b += rgbSecondary.b * ratio;

      totalRatio += ratio;
    }

    // normalize the individual spectrum values if totalRatio is not 1 (may be slightly higher or slightly lower)
    const result = !totalRatio
      ? COLORS_NONE
      : {
          primary: `rgb(${primary.r / totalRatio}, ${primary.g / totalRatio}, ${
            primary.b / totalRatio
          })`,
          secondary: `rgb(${secondary.r / totalRatio}, ${
            secondary.g / totalRatio
          }, ${secondary.b / totalRatio})`,
        };

    return result;
  }, [state.intersectRatios, state.panelColors]);

  /** Properly dispose on unmount */

  useUnmount(() => {
    // clean up toggle event listeners
    for (const id in state.toggleRefs) {
      const ref = state.toggleRefs[id];
      const handlers = state.toggleHandlers[id];
      for (const handler in handlers) {
        ref.current?.removeEventListener(
          handler,
          handlers[handler as keyof typeof handlers]
        );
      }
    }
    // clean up panel observer
    for (const id in state.panelIds) {
      const ref = state.panelRefs[id];
      if (ref?.current) {
        intersectObserver.unobserve(ref.current);
      }
    }
  });

  /** Providing the context */
  const handleNavigateUp = useMemo(() => {
    if (state.nav.up) {
      return () =>
        state.panelRefs[state.nav.up as string].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  }, [state.nav.up, state.panelRefs]);

  const handleNavigateDown = useMemo(() => {
    if (state.nav.down) {
      return () =>
        state.panelRefs[state.nav.down as string].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  }, [state.nav.down, state.panelRefs]);

  const context = useMemo(
    () => ({
      color: {
        current: currentColor,
        next: state.nextColor,
      },
      panelColors: state.panelColors,
      registerPanel,
      unregisterPanel,
      registerToggle,
      unregisterToggle,
    }),
    [
      currentColor,
      state.nextColor,
      state.panelColors,
      registerPanel,
      unregisterPanel,
      registerToggle,
      unregisterToggle,
    ]
  );

  const Variation = useMemo(() => variations[variation], [variation]);

  // TODO(alipianu): need to fix theme accent color issues on small break points, do break points and panel height better (latest CSS, not 100% height tables, etc.).
  return (
    <ThemeContext.Provider value={context}>
      <div
        className="theme"
        style={
          {
            "--theme__current--primary": context.color?.current.primary,
            "--theme__current--secondary": context.color?.current.secondary,
            "--theme__next--primary": context.color?.next?.primary,
            "--theme__next--secondary": context.color?.next?.secondary,
          } as CSSProperties
        }
      >
        <Variation
          onNavigateUp={handleNavigateUp}
          onNavigateDown={handleNavigateDown}
        />
        <div className="theme__children">{children}</div>
      </div>
    </ThemeContext.Provider>
  );
};

export default Theme;
