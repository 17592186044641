import request from '../utils/request';
import config from '../config.json';
import { HomeData } from '../data-types';
import { DisplayNameable, Response } from '../types';
import { VIEW_HOME_DISPLAY_NAME } from '../constants';

/**
 * Maps a view to a content id, used to retrieve a view's data
 */
const VIEW_TO_CONTENT_ID = {
  [VIEW_HOME_DISPLAY_NAME]: 0 as const,
};

/**
 * Maps a view to a content type, provides typing for a view
 */
interface ViewToContentType {
  [VIEW_HOME_DISPLAY_NAME]: HomeData;
}

/**
 * Maps content id to content type, aSuto-generated based off of VIEW_TO_CONTENT_ID and ViewToContentType (keys of both MUST match)
 */
type ContentData = {
  [K in keyof typeof VIEW_TO_CONTENT_ID as typeof VIEW_TO_CONTENT_ID[K]]: ViewToContentType[K];
};

/**
 * Get content by content id
 */
export const getContentByContentId = (
  contentId: keyof ContentData
): Promise<ContentData[keyof ContentData]> =>
  request.get('s3', `/content/${contentId}/version/${config.version}.json`);

/**
 * Get content by view
 */
export const getContentByView = (
  view: DisplayNameable<keyof typeof VIEW_TO_CONTENT_ID>
) => getContentByContentId(VIEW_TO_CONTENT_ID[view.displayName]);

const Static = {
  getContentByContentId,
  getContentByView,
};
export default Static;
