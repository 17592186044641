import { useCallback } from "react";

import { render as renderSection } from "../../sections";
import { Loader, Polyhedrons, Theme } from "../../components";
import { Static } from "../../controllers";
import { VIEW_HOME_DISPLAY_NAME } from "../../constants";
import { HomeData } from "../../data-types";

/**
 * Home view
 *
 * usage: <Home />
 */
export const Home = () => {
  const load = useCallback(() => Static.getContentByView(Home), []);
  return (
    <Loader animation={Polyhedrons} load={load}>
      {({ sections }: HomeData) => (
        <Theme>
          {sections.map(({ id, name, data }) => renderSection(name, data, id))}
        </Theme>
      )}
    </Loader>
  );
};
Home.displayName = VIEW_HOME_DISPLAY_NAME;

export default Home;
