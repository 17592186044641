import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useCallback,
  useState,
} from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Ribbon } from "..";
import { Icon, ThemeColors, Themed } from "../../types";
import { useToggle } from "../theme";

import "./index.scss";

const NONE = -1;
const SM = "sm";
const LG = "lg";

interface ToggleData {
  theme: ThemeColors;
  icon: Icon;
  name: string;
  url?: string;
}

interface ToggleProps extends ToggleData, Themed {
  id: number;
  type: typeof SM | typeof LG;
  isActive: boolean;
  transform?: string;
  onClick?: (id: ToggleProps["id"], type: ToggleProps["type"]) => void;
  "aria-label"?: string;
  href?: string;
}

const Toggle = ({
  id,
  "aria-label": ariaLabel,
  href,
  type,
  isActive,
  transform,
  onClick,
  name,
  icon,
  url,
  theme,
  panelId,
}: ToggleProps) => {
  const handleClick = useCallback(
    () => onClick?.(id, type),
    [id, type, onClick]
  );
  const ref = useToggle(panelId, theme);

  return !(url || href) ? (
    // build spans with onClick handler
    <span
      ref={ref}
      className={cx("toggles__toggle", `toggles__toggle--${type}`, {
        "toggles__toggle--active": isActive,
      })}
      onClick={handleClick}
    >
      <div className="toggles__toggle__icon" style={{ transform }}>
        <FontAwesomeIcon
          icon={[icon.style || "fas", icon.name || "question-circle"]}
        />
      </div>
      {type === LG && name && (
        <Ribbon>
          <h2>{name}</h2>
        </Ribbon>
      )}
    </span>
  ) : (
    // build anchors with href
    <a
      ref={ref as MutableRefObject<HTMLAnchorElement>}
      className={cx("toggles__toggle", `toggles__toggle--${type}`, {
        "toggles__toggle--active": isActive,
      })}
      aria-label={ariaLabel || name || `${icon || "question-circle"} button`}
      href={url || href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div style={{ transform }}>
        <FontAwesomeIcon
          style={{ transform }}
          icon={[icon.style || "fas", icon.name || "question-circle"]}
        />
      </div>
      {type === LG && name && (
        <Ribbon>
          <h2>{name}</h2>
        </Ribbon>
      )}
    </a>
  );
};

interface TogglesProps extends Themed {
  onClick?: ToggleProps["onClick"];
  type?: ToggleProps["type"];
  isMobile: boolean;
  data: ToggleData[];
}

/**
 * Toggles component
 *
 * usage: <Toggles innerRef?={W} type?={X} title?={Y} className?={Z}>...</Ribbon>
 */
const Toggles = forwardRef(
  (
    { panelId, onClick, type, isMobile, data }: TogglesProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const [activeId, setActiveId] = useState(NONE);

    const handleClick = useCallback(
      (id: ToggleProps["id"], type: ToggleProps["type"]) => {
        // clear currently active
        if (activeId === id) {
          setActiveId(NONE);
        }
        // set new active
        else {
          setActiveId(id);
        }
        onClick?.(id, type);
      },
      [activeId, setActiveId, onClick]
    );

    return (
      <>
        {(type === LG || (type !== SM && activeId === NONE)) && (
          <div
            ref={type === LG ? ref : undefined}
            className="toggles toggles--lg"
          >
            {data.map((toggle, key) => (
              <Toggle
                {...toggle}
                id={key}
                key={key}
                type={LG}
                onClick={handleClick}
                isActive={activeId === key}
                panelId={panelId}
              />
            ))}
          </div>
        )}
        {(type === SM || (type !== LG && activeId !== NONE)) && (
          <div
            ref={ref}
            className={cx("toggles", "toggles--sm", {
              "toggles--mobile": isMobile,
            })}
          >
            {data.map((toggle, key) => (
              <Toggle
                {...toggle}
                id={key}
                key={key}
                type={SM}
                onClick={handleClick}
                isActive={activeId === key}
                panelId={panelId}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);
Toggles.displayName = "Toggles";

export default Toggles;
