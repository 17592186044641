import { ReactNode, useCallback, useMemo, useState } from "react";
import { useInterval, useMount } from "react-use";
import { Subject } from "rxjs";

import { counter, Polyhedron } from "./polyhedrons";
import Face from "./polyhedrons-face";

import "./index.scss";

const ANIMATION_DURATION = 11000;
const DEFAULT_ANIMATED_ID = -1;

interface Props {
  title: ReactNode;
}

// get random number in [min, max]
const rand = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

// generate random keyframe for polyhedron animation
const randKeyframe = () => {
  const fromTransform = "rotateY(0deg) rotateX(0deg) scale(0)";
  const toTranform = `rotateY(${rand(-400, 400)}deg) rotateX(${rand(
    -400,
    400
  )}deg) scale(1)`;
  return `
    @keyframes spin-shapes {
      from {
        -webkit-transform: ${fromTransform};
            -ms-transform: ${fromTransform};
                transform: ${fromTransform};
      }
      to {
        -webkit-transform: ${toTranform};
            -ms-transform: ${toTranform};
                transform: ${toTranform};
      }
    }
  `;
};

export const Polyhedrons = ({ title }: Props) => {
  const [keyframe, setKeyFrame] = useState("");
  const [animatedId, setAnimatedId] = useState(DEFAULT_ANIMATED_ID);
  const [animationChange] = useState(new Subject<number>());
  const animationObservable = useMemo(
    () => animationChange.asObservable(),
    [animationChange]
  );

  const updateShape = useCallback(() => {
    let id: number;
    do {
      id = rand(0, counter);
    } while (id === animatedId);
    setAnimatedId(id);
    setKeyFrame(randKeyframe());
    animationChange.next(id);
  }, [animatedId, setAnimatedId, setKeyFrame, animationChange]);

  useMount(updateShape);
  useInterval(updateShape, ANIMATION_DURATION);

  return (
    <div className="scene">
      <div className="shapes">
        <Polyhedron type="tetrahedron" animatedId={animatedId}>
          {(parentId) => (
            <>
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
            </>
          )}
        </Polyhedron>
        <Polyhedron type="triang-prism" animatedId={animatedId}>
          {(parentId) => (
            <>
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
            </>
          )}
        </Polyhedron>
        <Polyhedron type="cube" animatedId={animatedId}>
          {(parentId) => (
            <>
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
              <Face
                rect
                parentId={parentId}
                animationChange={animationObservable}
              />
            </>
          )}
        </Polyhedron>
        <Polyhedron type="octahedron" animatedId={animatedId}>
          {(parentId) => (
            <>
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
              <Face parentId={parentId} animationChange={animationObservable} />
            </>
          )}
        </Polyhedron>
      </div>
      {title}
      <style dangerouslySetInnerHTML={{ __html: keyframe }} />
    </div>
  );
};

export default Polyhedrons;
