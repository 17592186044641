import "./polyhedrons-face.scss";
import { useEffect } from "react";
import { useMap, useObservable, usePrevious } from "react-use";
import cx from "classnames";
import { Observable } from "rxjs";

const NONE = -1;

interface PolyhedronFaceStyle {
  borderColor?: string;
  backgroundColor?: string;
}

interface Props {
  rect?: boolean;
  parentId: number;
  animationChange: Observable<number>;
}

/**
 * Generates random number in range [0, 255]
 *
 * @returns random number in range [0, 255]
 */
export const rand255 = () => Math.floor(Math.random() * 256);

/**
 * Generates random RGBA string
 *
 * @returns random RGBA string
 */
export const rgba = () => `rgba(${rand255()}, ${rand255()}, ${rand255()}, 0.7)`;

export const PolyhedronFace = ({
  rect = false,
  parentId,
  animationChange,
}: Props) => {
  const id = useObservable(animationChange, NONE);
  const prevId = usePrevious(id);
  const [style, { setAll: setStyle }] = useMap<PolyhedronFaceStyle>({
    backgroundColor: "",
    borderColor: "",
  });

  useEffect(() => {
    if (id === parentId && id !== prevId) {
      setStyle(
        rect
          ? { backgroundColor: rgba() }
          : { borderColor: `transparent transparent ${rgba()} transparent` }
      );
    }
  }, [id, prevId, parentId, rect, setStyle]);

  return <div className={cx("shape-face", rect && "rect")} style={style}></div>;
};

export default PolyhedronFace;
